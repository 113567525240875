import { Fragment } from "react";
import Footer from "../../components/footer/footer.component";
import Gallery from "../../components/gallery/gallery.component";
import "./projectgallery.styles.scss";

const ProjectGallery = () => {
  return (
    <Fragment>
      <div className="section-without-width-gallery">
        <Gallery />
      </div>
      <Footer />
    </Fragment>
  );
};

export default ProjectGallery;
