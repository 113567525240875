import "./heroBtn.styles.scss";
import { useLottie } from "lottie-react";
import HeroBtnAnimation from "../../assets/herobtn.json";

const HeroBtn = (props) => {
  const options = {
    animationData: HeroBtnAnimation,
    loop: true,
  };
  const { View } = useLottie(options);

  return (
    <div className="hero-btn-background">
      <div className="hero-btn" onClick={props.onClick}>
        {View}
      </div>
    </div>
  );
};

export default HeroBtn;
