import "./service.styles.scss";
import OurServices from "../../components/ourservices/ourservices.component";
import Footer from "../../components/footer/footer.component";
import { Fragment } from "react";

const Service = () => {
  return (
    <Fragment>
      <div className="section-without-width">
        <OurServices />
      </div>
      <Footer />
    </Fragment>
  );
};

export default Service;
