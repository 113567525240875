import "./form-text.styles.scss";

const FormText = ({ label, name, ...otherProps }) => {
  return (
    <div className={`group ${name}`}>
      <textarea
        className={`form-input textarea ${name}`}
        name={name}
        {...otherProps}
      />
      {label && (
        <label
          className={`${
            otherProps.value.length ? "shrink" : ""
          } form-input-label`}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default FormText;
