import "./home.styles.scss";
import Footer from "../../components/footer/footer.component";
import ThemeButton from "../../components/themebutton/themebutton.component";
import Hero from "../../components/hero/hero.component";
import HeroBtn from "../../components/herobtn/heroBtn.component";

import SectionOne from "../../components/sectionone/sectionone.component";
import SectionTwo from "../../components/sectiontwo/sectiontwo.component";
import SectionThree from "../../components/serctionthree/sectionthree.component";
import OurServices from "../../components/ourservices/ourservices.component";
import Gallery from "../../components/gallery/gallery.component";
import Reviews from "../../components/reviews/reviews.component";

import { useRef, useState, useEffect } from "react";
import FloatingBtn from "../../components/floatingbtn/floatingbtn.component";

import { useSelector, useDispatch } from "react-redux";
import { toggleTheme } from "../../store/theme";
import { toggleStart } from "../../store/start";
import Intro from "../../components/intro/intro.component";

const Home = () => {
  const [showFloatingBtn, setShowFloatingBtn] = useState(false);
  const [showViewBtn, setShowViewBtn] = useState(false);
  const sectionOneRef = useRef(null);
  const reviewRef = useRef(null);

  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.theme);
  const { start } = useSelector((state) => state.start);

  useEffect(() => {
    setShowViewBtn(true);

    const handleScroll = () => {
      if (sectionOneRef.current && reviewRef.current) {
        const isBetweenSections =
          window.scrollY > sectionOneRef.current.offsetTop &&
          window.scrollY < reviewRef.current.offsetTop;
        setShowFloatingBtn(isBetweenSections);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollDown = () => {
    console.log("scrolling down");
    window.scrollTo({
      top: sectionOneRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div className="home-wrapper">
      <div
        className={`home-hero-section1 ${start ? "non-visible" : "visible"}`}
        style={{ zIndex: start ? 10 : 100 }}
      >
        <div className="intro">
          <div className="intro-flex-wrapper">
            <div
              className={`lines-wrapper ${
                showViewBtn ? "yes-lines" : "no-lines"
              }`}
            ></div>
            <div className="intro-logo">
              <Intro />
            </div>
            <div className="intro-text-wrapper">Where value meets quality</div>
            <div
              className={`intro-button-wrapper ${
                showViewBtn ? "yes-view" : "no-view"
              }`}
              onClick={() => {
                dispatch(toggleStart());
              }}
            >
              View
            </div>
          </div>
        </div>
      </div>
      <div className={`after-intro ${start ? "display" : "non-display"}`}>
        <div className="home-hero-section">
          <div className="background-cover">
            <div
              className={`background-cover-image ${
                theme ? "commercial" : "residential"
              }`}
            />
          </div>
          <div className="grid-wrapper">
            <div className="residential-commercial-btn-container">
              <ThemeButton
                themeValue={theme}
                handleToggle={() => {
                  dispatch(toggleTheme());
                }}
              />
            </div>
            <div className="hero-text-section">
              <Hero />
            </div>
            <div className="hero-section-fill" />
            <div className="hero-section-fill-right" />

            <div className="hero-section-btn">
              <HeroBtn onClick={scrollDown} />
            </div>
          </div>
        </div>

        <div
          className={`floating-button-wrapper ${
            showFloatingBtn ? "showFloatingBtn" : ""
          }`}
        >
          <FloatingBtn />
        </div>

        <div className="section-without-width" ref={sectionOneRef}>
          <SectionOne />
        </div>

        <div className="section-with-width">
          <SectionTwo />
        </div>

        <div className="section-with-width">
          <SectionThree />
        </div>

        <div className="section-without-width">
          <OurServices />
        </div>

        <div className="section-without-width">
          <Gallery />
        </div>

        <div className="section-without-width" ref={reviewRef}>
          <Reviews />
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Home;
