import Footer from "../../components/footer/footer.component";
import "./about.styles.scss";
import { Parallax } from "react-scroll-parallax";
import headshot from "../../assets/headshot.png";
import { getHeight } from "react-slick/lib/utils/innerSliderUtils";
import SectionTwo from "../../components/sectiontwo/sectiontwo.component";

const About = () => {
  return (
    <div className="about-wrapper">
      <div className="about-hero-section" id="top">
        <>
          <div className="parallax1-container1">
            <Parallax className="slow-background1" speed={-10} />
          </div>
          <div className="parallax1-container2">
            <Parallax className="fast-text" speed={10}>
              <div className="fast">
                <div className="fast-header">
                  Where Value Meets{" "}
                  <span className="highlighted-text">Quality</span>
                </div>
                <div className="fast-content1">
                  <div className="content-header-circle">OUR MISSION</div>
                  <div className="content-header-text">
                    Provide exceptional value and quality in every project,
                    creating beautiful and functional spaces.
                  </div>
                </div>
                <div className="fast-content2">
                  <div className="content-header-circle">OUR APPROACH</div>
                  <div className="content-header-text">
                    Offer one-stop service by Chief Executive Officer,{" "}
                    <span className="bold-text">Lhakpa Sherpa</span>, handling
                    the project from start to finish, making the process simple
                    and stress-free.
                  </div>
                </div>
              </div>
            </Parallax>
          </div>
        </>
      </div>
      <div className="about-hero-section2">
        <>
          <div className="parallax2-container1">
            <Parallax className="slow-background1" speed={-10} />
          </div>
          <div className="parallax2-container2">
            <Parallax className="fast-text2" speed={10}>
              <div className="fast2">
                <div className="fast-header">
                  We provide you the{" "}
                  <span className="highlighted-text">best experience</span>
                </div>

                <div className="section-sub-stats-wrapper">
                  <div className="section-sub-stats-container">
                    <div className="section-sub-stats-number">23</div>
                    <div className="section-sub-stats-text1">Years</div>
                    <div className="section-sub-stats-text2">Experience</div>
                  </div>
                  <div className="section-sub-stats-container">
                    <div className="section-sub-stats-number">5.0</div>
                    <div className="section-sub-stats-text1">Customer</div>
                    <div className="section-sub-stats-text2">Reviews</div>
                  </div>
                  <div className="section-sub-stats-container">
                    <div className="section-sub-stats-number">230</div>
                    <div className="section-sub-stats-text1">Projects</div>
                    <div className="section-sub-stats-text2">Done</div>
                  </div>
                </div>
              </div>
            </Parallax>
          </div>
        </>
      </div>
      <div className="section-without-width-about">
        <div className="owner-wrapper">
          <div className="owner-title-wrapper">
            <div className="owner-title-container">Meet Lhakpa Sherpa</div>
            <div className="CEO">Chief Executive Officer </div>
          </div>
          <div className="owner-location">
            <SectionTwo />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
