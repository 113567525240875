import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.styles.scss";
import { ReactComponent as PreviousBtn } from "../../assets/previousBtn.svg";
import { ReactComponent as NextBtn } from "../../assets/nextBtn.svg";
import { useRef, useState } from "react";
import Slider from "react-slick";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const arrowRef = useRef(null);
  const settings = {
    className: "center",
    centerMode: true,
    variableWidth: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    arrows: false,

    autoplay: true,

    autoplaySpeed: 3000,
    pauseOnHover: true,

    beforeChange: (oldIndex, newIndex) => {
      setCurrentIndex(newIndex);
    },

    responsive: [
      // {
      //   breakpoint: 890,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //     infinite: true,
      //     centerMode: false,
      //     // dots: true,
      //   },
      // },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //     initialSlide: 2,
      //   },
      // },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };

  const clickImageHandler = (event) => {
    const targetIndex = event.target.getAttribute("index");
    if (targetIndex > currentIndex) {
      arrowRef.current.slickNext();
    } else if (targetIndex < currentIndex) {
      arrowRef.current.slickPrev();
    } else if (targetIndex === currentIndex) {
      return;
    }
  };

  return (
    <>
      <div className="carousel-wrapper">
        <Slider ref={arrowRef} {...settings}>
          <img
            src="https://images.unsplash.com/photo-1618219908412-a29a1bb7b86e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=654&q=80"
            alt=""
            index="0"
            onClick={clickImageHandler}
          />
          <img
            src="https://images.unsplash.com/photo-1600210492493-0946911123ea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8aW50ZXJpb3J8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
            alt=""
            index="1"
            onClick={clickImageHandler}
          />
          <img
            src="https://images.unsplash.com/photo-1618219908412-a29a1bb7b86e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=654&q=80"
            alt=""
            index="2"
            onClick={clickImageHandler}
          />
          <img
            src="https://images.unsplash.com/photo-1600210492493-0946911123ea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8aW50ZXJpb3J8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
            alt=""
            index="3"
            onClick={clickImageHandler}
          />
          <img
            src="https://images.unsplash.com/photo-1618219908412-a29a1bb7b86e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=654&q=80"
            alt=""
            index="4"
            onClick={clickImageHandler}
          />
          <img
            src="https://images.unsplash.com/photo-1600210492493-0946911123ea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8aW50ZXJpb3J8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
            alt=""
            index="5"
            onClick={clickImageHandler}
          />
          <img
            src="https://images.unsplash.com/photo-1618219908412-a29a1bb7b86e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=654&q=80"
            alt=""
            index="6"
            onClick={clickImageHandler}
          />
          <img
            src="https://images.unsplash.com/photo-1600210492493-0946911123ea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8aW50ZXJpb3J8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
            alt=""
            index="7"
            onClick={clickImageHandler}
          />
          <img
            src="https://images.unsplash.com/photo-1618219908412-a29a1bb7b86e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=654&q=80"
            alt=""
            index="8"
            onClick={clickImageHandler}
          />
          <img
            src="https://images.unsplash.com/photo-1600210492493-0946911123ea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8aW50ZXJpb3J8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
            alt=""
            index="9"
            onClick={clickImageHandler}
          />
          <img
            src="https://images.unsplash.com/photo-1618219908412-a29a1bb7b86e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=654&q=80"
            alt=""
            index="10"
            onClick={clickImageHandler}
          />
          <img
            src="https://images.unsplash.com/photo-1600210492493-0946911123ea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8aW50ZXJpb3J8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60"
            alt=""
            index="11"
            onClick={clickImageHandler}
          />
        </Slider>

        <PreviousBtn
          onClick={() => arrowRef.current.slickPrev()}
          className="previous-btn"
        />
        <NextBtn
          onClick={() => arrowRef.current.slickNext()}
          className="next-btn"
        />
      </div>
    </>
  );
};

export default Carousel;
