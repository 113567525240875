import "./form-input.styles.scss";

const FormInput = ({ label, name, ...otherProps }) => {
  return (
    <div className={`group ${name}`}>
      <input className={`form-input ${name}`} name={name} {...otherProps} />
      {label && (
        <label
          className={`${
            otherProps.value.length ? "shrink" : ""
          } form-input-label`}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default FormInput;
