import "./sectionthree.styles.scss";
import { ReactComponent as VerticalLine } from "../../assets/line.svg";
import { useSelector } from "react-redux";

const SectionThree = () => {
  const { theme } = useSelector((state) => state.theme);
  return (
    <div className="section-with-width-wrapper-section-three">
      <div className="section-text-wrapper2">
        <div className="section-text-header-wrapper">
          <div className="section-text-header">
            We ensure the best process experience
          </div>
        </div>

        <div className="section-text-sub-header-wrapper">
          <div className="section-text-sub-header-container">
            <div className="section-text-first-row">
              <div className="number">01</div>
              <div className="title">Planning with Quote</div>
            </div>
            <div className="section-text-second-row">
              <div>
                <VerticalLine />
              </div>
              <div className="info">
                Our quote process is thorough and transparent, ensuring that our
                clients have a clear understanding of the scope and cost of
                their project before work begins.
              </div>
            </div>
          </div>

          <div className="section-text-sub-header-container">
            <div className="section-text-first-row">
              <div className="number">02</div>
              <div className="title">Processing</div>
            </div>
            <div className="section-text-second-row">
              <div>
                <VerticalLine />
              </div>
              <div className="info">
                We have a streamlined process for initiating work on a project.
                Once a quote has been agreed upon, our team of professionals
                begins the work promptly and efficiently.
              </div>
            </div>
          </div>

          <div className="section-text-sub-header-container">
            <div className="section-text-first-row">
              <div className="number">03</div>
              <div className="title">Finish & Review</div>
            </div>
            <div className="section-text-second-row">
              <div className="bar">
                <VerticalLine />
              </div>
              <div className="info">
                Upon completion of the project, we conduct a thorough review to
                ensure that our clients are satisfied with the result. We
                encourage feedback and take it into consideration as we
                continually strive to improve our services.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`section-image2 ${theme ? "commercial2" : "residential2"}`}
      />
    </div>
  );
};

export default SectionThree;
