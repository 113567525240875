import "./footer.styles.scss";
import { ReactComponent as FooterLogo } from "../../assets/footerlogo.svg";
import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section">
        <div className="footer-section-row1">
          <div className="footer-company-left-container">
            <FooterLogo className="footer-company-logo" />
            <div className="footer-company-text">
              Combining years of experience with a dedication to client
              satisfaction, Sherpa Painting Service Inc. has established itself
              as the local leader in Residential Painting and Commercial
              Painting.
            </div>
          </div>
          <div className="footer-row1-divider" />

          <div className="footer-company-right-container">
            <div className="footer-company-company">
              <div className="footer-company-text-header1">Company</div>
              <Link className="footer-about-link" to="/about">
                Learn more about us
              </Link>
              <Link className="footer-partner-link" to="/contact">
                Partner with us
              </Link>
            </div>

            <div className="footer-company-details">
              <div className="footer-company-text-container">
                <div className="footer-company-text-header2">
                  Contact Details
                </div>
                <div>Patterson, NY, United States</div>
                <div>(914) 406-9964</div>
                <div>sherpapnc@gmail.com</div>
              </div>

              <div className="footer-company-sns">
                <a
                  href="https://www.facebook.com/Sherpapaintingandcarpentry/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook className="facebook-logo" />
                </a>
                <a
                  href="https://www.instagram.com/sherpa_pnc/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram className="instagram-logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-section-row2">
          © 2023 Sherpa Painting & Carpentry | All Rights Reserved | Designed by
          Won
        </div>
      </div>
    </div>
  );
};

export default Footer;
