import { Link, Outlet } from "react-router-dom";
import React from "react";
import "./navigation.styles.scss";
import CustomLink from "./customlink.component";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { ReactComponent as MainLogo } from "../../assets/Sherpa.svg";
import HamburgerMenu from "../../components/hamburger-menu/hamburger-menu.component";
import { ReactComponent as Call } from "../../assets/call.svg";
import { ReactComponent as Quote } from "../../assets/quote.svg";

const Navigation = () => {
  // new:
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  // new:
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    // set state based on location info (explained in more detail below)
    setVisible(prevScrollPos > currentScrollPos);

    // set state to new scroll position
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const { start } = useSelector((state) => state.start);

  return (
    <React.Fragment>
      <div
        className="navigation-box"
        style={{ top: visible && start ? "0" : "-120px" }}
      >
        <div className="navigation-wrapper">
          <Link className="logo-container" to="/">
            <MainLogo className="logo" />
          </Link>
          <div className="nav-links-container">
            <CustomLink className="nav-link" to="/">
              Home
            </CustomLink>
            <CustomLink className="nav-link" to="/about">
              About
            </CustomLink>
            <CustomLink className="nav-link" to="/service">
              Service
            </CustomLink>
            <CustomLink className="nav-link" to="/gallery">
              Gallery
            </CustomLink>
            <CustomLink className="nav-link" to="/contact">
              Contact
            </CustomLink>
          </div>

          <Link className="nav-free-quote" to="/contact">
            Get a free quote
          </Link>
          {/* Hamburger menu icon for mobile and tablet */}
          <div className="mobile-nav">
            <a href="tel:+9144069964" className="mobile-contact-container">
              <Call className="call-icon" />
              <span className="text">Call</span>
            </a>
            <Link to="/contact" className="mobile-contact-container quote-none">
              <Quote className="quote-icon" />
              <span className="text">Quote</span>
            </Link>
            <HamburgerMenu />
          </div>
        </div>
      </div>
      <Outlet />
    </React.Fragment>
  );
};

export default Navigation;

// make function using usematch and useresolvedpath to get the path of the current page and give it a className of active to the link
