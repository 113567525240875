import "./whyustext.styles.scss";
import { ReactComponent as BestQuality } from "../../assets/bestQuality.svg";
import { ReactComponent as Reliabilty } from "../../assets/reliabilty.svg";
import { ReactComponent as Warranty } from "../../assets/warranty.svg";

const WhyUsText = () => {
  return (
    <div className="whyus-text-wrapper">
      <div className="whyus-text-card">
        <div className="whyus-text-card-header-wrap">
          <BestQuality className="whyus-text-card-header-wrap-svg best-icon" />
          <div className="whyus-text-card-header-wrap-txt">Top Quality</div>
        </div>
        <div className="whyus-text-card-sub-wrap">
          We provide top-notch craftsmanship and ensure that all of our clients
          are satisfied with the quality of our work.
        </div>
      </div>
      <div className="whyus-text-card">
        <div className="whyus-text-card-header-wrap">
          <Reliabilty className="whyus-text-card-header-wrap-svg reliablity-icon" />
          <div className="whyus-text-card-header-wrap-txt">Reliablity</div>
        </div>
        <div className="whyus-text-card-sub-wrap">
          We have a team of highly trained and experienced professionals who
          consistently deliver top-quality work.
        </div>
      </div>

      <div className="whyus-text-card">
        <div className="whyus-text-card-header-wrap">
          <Warranty className="whyus-text-card-header-wrap-svg warranty-icon" />
          <div className="whyus-text-card-header-wrap-txt">Warranty</div>
        </div>
        <div className="whyus-text-card-sub-wrap">
          We provide a warranty for our work and are committed to ensuring that
          our customers are satisfied with the end result.
        </div>
      </div>
    </div>
  );
};

export default WhyUsText;
