import { createSlice } from "@reduxjs/toolkit";

export const startSlice = createSlice({
  name: "start",
  initialState: {
    start: false,
  },
  reducers: {
    toggleStart: (state) => {
      state.start = true;
    },
  },
});

export const { toggleStart } = startSlice.actions;
export default startSlice.reducer;
