import { Link, useMatch, useResolvedPath } from "react-router-dom";

const CustomLink = ({ to, children, ...props }) => {
  const resolvedPath = useResolvedPath(to);
  //   const match = useMatch({ path: resolvedPath.pathname, end: true });
  const match = useMatch(to);
  const active = match ? "nav-link active" : "nav-link";
  return (
    <Link to={resolvedPath} className={active}>
      {children}
    </Link>
  );
};

export default CustomLink;
