import "./review-carousel.styles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { ReactComponent as DoubleQuote } from "../../assets/doublequote.svg";
import { useSelector } from "react-redux";

const residentialData = [
  {
    header: "The results given are very satisfying.",
    text: "Sherpa Painting did a fantastic job renovating my brand new home interiors. Their prices are reasonable, and the results given are very satisfying.",
    author: "Joan Gillen",
  },
  {
    header: "They are trustworthy and skilled.",
    text: "They are trustworthy and skilled. They are meticulous in their work and produce excellent results without ever seeking shortcuts or other methods to save time.",
    author: "Merri Adams",
  },
  {
    header: "The results given are very satisfying.",
    text: "Sherpa Painting did a fantastic job renovating my brand new home interiors. Their prices are reasonable, and the results given are very satisfying.",
    author: "John Doe",
  },
  {
    header: "They are trustworthy and skilled.",
    text: "They are trustworthy and skilled. They are meticulous in their work and produce excellent results without ever seeking shortcuts or other methods to save time.",
    author: "John Doe",
  },
  {
    header: "The results given are very satisfying.",
    text: "Sherpa Painting did a fantastic job renovating my brand new home interiors. Their prices are reasonable, and the results given are very satisfying.",
    author: "John Doe",
  },
  {
    header: "They are trustworthy and skilled.",
    text: "They are trustworthy and skilled. They are meticulous in their work and produce excellent results without ever seeking shortcuts or other methods to save time.",
    author: "John Doe",
  },
];

const commercialData = [
  {
    header: "The results given are very satisfying.",
    text: "Sherpa Painting did a fantastic job renovating my brand new home interiors. Their prices are reasonable, and the results given are very satisfying.",
    author: "Google",
  },
  {
    header: "They are trustworthy and skilled.",
    text: "They are trustworthy and skilled. They are meticulous in their work and produce excellent results without ever seeking shortcuts or other methods to save time.",
    author: "Facebook",
  },
  {
    header: "The results given are very satisfying.",
    text: "Sherpa Painting did a fantastic job renovating my brand new home interiors. Their prices are reasonable, and the results given are very satisfying.",
    author: "Airbnb",
  },
  {
    header: "They are trustworthy and skilled.",
    text: "They are trustworthy and skilled. They are meticulous in their work and produce excellent results without ever seeking shortcuts or other methods to save time.",
    author: "Rocket Mortgage",
  },
  {
    header: "The results given are very satisfying.",
    text: "Sherpa Painting did a fantastic job renovating my brand new home interiors. Their prices are reasonable, and the results given are very satisfying.",
    author: "Carmel Scoops",
  },
  {
    header: "They are trustworthy and skilled.",
    text: "They are trustworthy and skilled. They are meticulous in their work and produce excellent results without ever seeking shortcuts or other methods to save time.",
    author: "Seven Eleven",
  },
];

const ReviewCarousel = () => {
  const { theme } = useSelector((state) => state.theme);
  const data = theme ? commercialData : residentialData;
  const settings = {
    className: "center slider review",
    centerMode: false,
    dots: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 1,

    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 640,
        settings: {
          centerMode: true,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider className="review-carousel-slider" {...settings}>
        {data.map((item, index) => {
          return (
            <div className="review-card" key={index}>
              <div className="review-card-wrapper">
                <DoubleQuote className="review-card-quote" />
                <div className="review-card-container">
                  <div className="review-card-header">{item.header}</div>
                  <div className="review-card-text">
                    {'"' + item.text + '"'}
                  </div>
                </div>
                <div className="author-container">
                  <div className="author-initial">{item.author.charAt(0)}</div>
                  <div className="author-name">{item.author}</div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
};

export default ReviewCarousel;
