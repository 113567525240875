import "./reviews.styles.scss";

import ReviewCarousel from "../review-carousel/review-carousel.component";
import { useSelector } from "react-redux";
import MultipleItems from "../review-carousel/carousel-test.component";

const Reviews = () => {
  const { theme } = useSelector((state) => state.theme);
  return (
    <div className="review-wrapper">
      <div className="review-title-wrapper">
        <div
          className={`review-title-container ${
            theme ? "commercial" : "retail"
          }`}
        >
          What Our{" "}
          <span style={{ color: "#FECE83" }}>{`${
            theme ? "Commercial Clients" : "Retail Clients"
          }`}</span>{" "}
          Say About Us
        </div>
      </div>
      <div className="review-cards-location">
        <div className="review-cards-wrapper">
          <ReviewCarousel />
        </div>
      </div>
    </div>
  );
};

export default Reviews;
