import "./sectiontwo.styles.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SectionTwo = () => {
  const { theme } = useSelector((state) => state.theme);

  return (
    <div className="section-with-width-wrapper-section-two">
      {/* <div className="section-image1-residential" /> */}
      <div
        className={`section-image1 ${theme ? "commercial" : "residential"}`}
      />

      <div className="section-text-wrapper1">
        <div className="section-text-container1">
          <div className="section-header1">
            We provide you the best experience
          </div>
          <div className="section-intro1">
            Our team of professionals has the knowledge and expertise to handle
            any painting or carpentry task with confidence and skill. We always
            ensure that our clients have a positive and seamless experience from
            start to finish. We proudly serve in Putnam County, Westchester
            County, Dutchess County, and Connecticut. Our services are fully
            licensed and insured in both New York and Connecticut States.
          </div>
        </div>

        <div className="section-sub-stats-wrapper">
          <div className="section-sub-stats-container">
            <div className="section-sub-stats-number">23</div>
            <div className="section-sub-stats-text1">Years</div>
            <div className="section-sub-stats-text2">Experience</div>
          </div>
          <div className="section-sub-stats-container">
            <div className="section-sub-stats-number">5.0</div>
            <div className="section-sub-stats-text1">Customer</div>
            <div className="section-sub-stats-text2">Reviews</div>
          </div>
          <div className="section-sub-stats-container">
            <div className="section-sub-stats-number">230</div>
            <div className="section-sub-stats-text1">Projects</div>
            <div className="section-sub-stats-text2">Done</div>
          </div>
        </div>
        <Link className="learn-more-box" to="/about#top">
          Learn More
        </Link>
      </div>
    </div>
  );
};

export default SectionTwo;
