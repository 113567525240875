import "./themebutton.styles.scss";

const ThemeButton = ({ themeValue, handleToggle }) => {
  return (
    <div className="theme-button-container">
      <div className="theme-button-text">Residential</div>
      <>
        <input
          className="react-switch-checkbox"
          id={`react-switch-new`}
          type="checkbox"
          checked={themeValue}
          onChange={handleToggle}
        />
        <label className="react-switch-label" htmlFor={`react-switch-new`}>
          <span className={`react-switch-button`} />
        </label>
      </>

      <div className="theme-button-text">Commercial</div>
      <div className="toggle-container"></div>
    </div>
  );
};

export default ThemeButton;
