import { Routes, Route } from "react-router-dom";

import Navigation from "./routes/navigation/navigation.component";
import Home from "./routes/home/home.component";
import About from "./routes/about/about.component";
import Contact from "./routes/contact/contact.component";
import Service from "./routes/service/service.component";
import ProjectGallery from "./routes/projectgallery/projectgallery.component";

import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//
import { ParallaxProvider } from "react-scroll-parallax";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== "/") {
      navigate("/");
    }
  }, []);

  return (
    <ParallaxProvider>
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="service" element={<Service />} />
          <Route path="gallery" element={<ProjectGallery />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </ParallaxProvider>
  );
}

export default App;
