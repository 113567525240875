import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./theme";
import startReducer from "./start";

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  // version: 1,
  blacklist: ["start"],
  storage,
};

const reducer = combineReducers({
  theme: themeReducer,
  start: startReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
