import "./gallery.styles.scss";
// import { useState } from "react";
import Carousel from "../carousel/carousel.component";

const Gallery = () => {
  return (
    <div className="gallery-wrapper">
      <div className="gallery-title-wrapper">
        <div className="gallery-title-container">Project Gallery</div>
      </div>
      <div className="gallery-cards-location">
        <div className="gallery-cards-wrapper">
          <Carousel />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
