import "./ourservices.styles.scss";
import { useState } from "react";
import { useSelector } from "react-redux";

const OurServices = () => {
  const { theme } = useSelector((state) => state.theme);
  const [cardClicked1, setCardClicked1] = useState(false);
  const [cardClicked2, setCardClicked2] = useState(false);
  const [cardClicked3, setCardClicked3] = useState(false);
  const [cardClicked4, setCardClicked4] = useState(false);

  const handleOutsideClick = () => {
    if (cardClicked1) {
      setCardClicked1(false);
    } else if (cardClicked2) {
      setCardClicked2(false);
    } else if (cardClicked3) {
      setCardClicked3(false);
    } else if (cardClicked4) {
      setCardClicked4(false);
    }
  };

  return (
    <div className="services-wrapper" onClick={handleOutsideClick}>
      <div className="services-title-wrapper">
        <div className="services-title-container">Our Services</div>
      </div>

      <div className="services-cards-location">
        <div className="services-cards-wrapper">
          <div
            className={`card-container ${cardClicked2 ? "clicked" : ""}`}
            id={2}
            onClick={() => {
              setCardClicked2(!cardClicked2);
            }}
          >
            <div
              className={`card-image ${
                theme ? "commercial-2" : "residential-2"
              }`}
            ></div>
            <div className="view-btn">View</div>
            <div className="card-information-container">
              <div className="card-title">{`${
                theme ? "Office Renovation" : "House Renovation"
              }`}</div>
              <div className="card-content">
                Roofing
                <br />
                Siding
                <br />
                HVAC Systems
                <br />
                Insulation
                <br />
                Sheetrock
                <br />
                Windows
                <br />
                Doors
                <br />
                Flooring
                <br />
                Kitchen
                <br />
                Baths
                <br />
                Plumbing
                <br />
                Electrical
              </div>
            </div>
          </div>
          <div
            className={`card-container ${cardClicked3 ? "clicked" : ""}`}
            id={3}
            onClick={() => {
              setCardClicked3(!cardClicked3);
            }}
          >
            <div
              className={`card-image ${
                theme ? "commercial-3" : "residential-3"
              }`}
            ></div>
            <div className="view-btn">View</div>
            <div className="card-information-container">
              <div className="card-title">Carpentry</div>
              <div className="card-content">
                Custom Carpentry
                <br />
                Kitchen
                <br />
                Bathroom
                <br />
                Basement
                <br />
                Porch
                <br />
                Decks / Patios
                <br />
                Windows
                <br />
                Room Addition
              </div>
            </div>
          </div>
          <div
            className={`card-container ${cardClicked1 ? "clicked" : ""}`}
            id={1}
            onClick={() => {
              setCardClicked1(!cardClicked1);
            }}
          >
            {/* <div className="card-image image1"></div> */}
            <div
              className={`card-image ${
                theme ? "commercial-1" : "residential-1"
              }`}
            ></div>
            <div className="view-btn">View</div>
            <div className="card-information-container">
              <div className="card-title">Interior & Exterior Painting</div>
              <div className="card-content">
                Wallpaper Decks
                <br />
                Fences
                <br />
                Patio Sealing
                <br />
                Clean & Paint
                <br />
                Gutters
                <br />
                Garage Floors
                <br />
                Lead Abatement
                <br />
                Wood Floors
                <br />
                Sheetrock Repair
                <br />
                Plaster Repair
                <br />
                Waterproofing
              </div>
            </div>
          </div>
          <div
            className={`card-container ${cardClicked4 ? "clicked" : ""}`}
            id={4}
            onClick={() => {
              setCardClicked4(!cardClicked4);
            }}
          >
            <div
              className={`card-image ${
                theme ? "commercial-4" : "residential-4"
              }`}
            ></div>
            <div className="view-btn">View</div>
            <div className="card-information-container">
              <div className="card-title">Masonary & More</div>
              <div className="card-content">
                Stone Walls
                <br />
                Chimney Services
                <br />
                Blue Stone Work
                <br />
                Paver Patios
                <br />
                Hardscaping
                <br />
                Landscaping
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
