import "./hero.component.scss";
import { useSelector } from "react-redux";

const Hero = () => {
  const { theme } = useSelector((state) => state.theme);
  return (
    <div className="hero-text-wrapper">
      <div className="hero-text-background">
        <div className="hero-text-container">
          <div className="hero-text-main">
            <div className={`white-text ${theme ? "commercial" : "hidden"}`}>
              ELEVATE YOUR SPACE WITH OUR{" "}
              <span className="highlighted-text">CRAFTSMANSHIP</span>
            </div>
            <div className={`white-text ${theme ? "hidden" : "residential"}`}>
              BUILD YOUR DREAM WITH OUR{" "}
              <span className="highlighted-text">CRAFTSMANSHIP</span>
            </div>

            {/* <div className="highlighted-text">CRAFTSMANSHIP</div> */}
          </div>

          <div className={`hero-text-sub ${theme ? "commercial" : "hidden"}`}>
            We provide top-notch Craftsmanship and innovation at an affordable
            price for your commercial space transformation.
          </div>
          <div className={`hero-text-sub ${theme ? "hidden" : "residential"}`}>
            We provide top-notch craftsmanship and innovative designs at an
            affordable price for your dream space.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
