import React, { useState } from "react";

import "./hamburger-menu.styles.scss";
import CustomLink from "../../routes/navigation/customlink.component";

function HamburgerMenu() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <div>
      <label
        className={`menu-label ${click ? "clicked" : ""}`}
        htmlFor="navi-toggle"
        onClick={handleClick}
      >
        <span className={`icon ${click ? "clicked" : ""}`}></span>
      </label>
      <div className={`nav-background ${click ? "clicked" : ""}`}></div>
      <nav className={`navigation ${click ? "clicked" : ""}`}>
        <div className={`box ${click ? "clicked" : ""}`}>
          <div className="circle one"></div>
          <div className="circle two"></div>
        </div>
        <div className="list" onClick={handleClick}>
          <CustomLink className="item-link" onClick={handleClick} to="/">
            Home
          </CustomLink>
          <CustomLink className="item-link" onClick={handleClick} to="/about">
            About
          </CustomLink>
          <CustomLink className="item-link" onClick={handleClick} to="/service">
            Service
          </CustomLink>
          <CustomLink className="item-link" onClick={handleClick} to="/gallery">
            Gallery
          </CustomLink>
          <CustomLink className="item-link" onClick={handleClick} to="/contact">
            Contact
          </CustomLink>
        </div>
      </nav>
    </div>
  );
}

export default HamburgerMenu;
