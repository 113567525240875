import "./floatingbtn.styles.scss";
import { useLottie } from "lottie-react";
import FloatingButton from "../../assets/floatingbtn.json";
import { Link } from "react-router-dom";

const FloatingBtn = (props) => {
  const options = {
    animationData: FloatingButton,
    loop: true,
  };
  const { View } = useLottie(options);

  return (
    <div className="floating-btn-container">
      <div className="floating-btn">
        <Link to="/contact">{View}</Link>
        <Link className="floating-btn-text" to="/contact">
          Get a free quote
        </Link>
      </div>
    </div>
  );
};

export default FloatingBtn;
