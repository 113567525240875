import "./contact.styles.scss";
import { useLottie } from "lottie-react";
import Construction from "../../assets/construction.json";
import Lottie from "lottie-react";
import { Fragment } from "react";
import Footer from "../../components/footer/footer.component";
import FormInput from "../../components/form-input/form-input.component";
import FormText from "../../components/form-text/form-text.component";
import { useState } from "react";

import { useRef } from "react";
import emailjs from "@emailjs/browser";
import Reaptcha from "reaptcha";

const defaultFormFields = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  zipCode: "",
  message: "",
};

const Contact = () => {
  const [verified, setVerified] = useState(false);
  const form = useRef();
  const captcha = useRef(null);

  const onVerify = (recaptchaResponse) => {
    setVerified(true);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!verified) {
      alert("Please verify that you are not a robot");

      const element = document.querySelector(".g-recaptcha");
      element.classList.add("heartbeat");

      return;
    } else {
      emailjs
        .sendForm(
          process.env.REACT_APP_YOUR_SERVICE_ID,
          process.env.REACT_APP_YOUR_TEMPLATE_ID,
          form.current,
          process.env.REACT_APP_YOUR_PUBLIC_KEY
        )

        .then(
          (result) => {
            console.log(result.text);
            console.log("message sent");
            alert("Message sent");
            // e.target.reset();
            setFormFields(defaultFormFields);
            captcha.current.reset();
          },
          (error) => {
            console.log(error.text);
          }
        );

      setFormFields(defaultFormFields);
      captcha.current.reset();
    }
  };

  const options = {
    animationData: Construction,
    loop: true,
  };
  const { View } = useLottie(options);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleChangePhone = (event) => {
    const { name, value } = event.target;
    const formattedPhoneNumber = formatPhoneNumber(value);
    setFormFields({ ...formFields, [name]: formattedPhoneNumber });
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7)
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const [formFields, setFormFields] = useState(defaultFormFields);

  const { firstName, lastName, email, phoneNumber, zipCode, message } =
    formFields;

  return (
    <Fragment>
      <div className="section-with-width-contact">
        <div className="section-with-width-wrapper">
          <div className="contact-left">
            <div className="contact-text">
              <div className="contact-text-header1">
                We’re here to help you.
              </div>
              <div className="contact-text-header2">
                Our door is always open for your dream space.
              </div>
            </div>
            <div className="contact-animation">
              <Lottie
                className="contact-animation-lottie"
                animationData={Construction}
                aria-aria-labelledby="use lottie animation"
                options={options}
              />
            </div>
          </div>
          <div className="contact-right">
            <form className="contact-form" ref={form} onSubmit={sendEmail}>
              <FormInput
                label="First Name*"
                type="text"
                required
                onChange={handleChange}
                name="firstName"
                value={firstName}
              />

              <FormInput
                label="Last Name*"
                type="text"
                required
                onChange={handleChange}
                name="lastName"
                value={lastName}
              />

              <FormInput
                label="Email*"
                type="email"
                required
                onChange={handleChange}
                name="email"
                value={email}
              />

              <FormInput
                label="Phone Number (optional)"
                type="text"
                onChange={handleChangePhone}
                name="phoneNumber"
                value={phoneNumber}
              />
              <FormInput
                label="ZIP Code*"
                type="number"
                required
                onChange={handleChange}
                name="zipCode"
                value={zipCode}
              />

              <FormText
                label="Message*"
                type="text"
                required
                onChange={handleChange}
                name="message"
                value={message}
              />

              <button type="submit">Submit Inquiry</button>
              <Reaptcha
                sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
                onVerify={onVerify}
                ref={captcha}
                // size="compact"
              />
            </form>

            <div className="service-term">
              This site is protected by reCAPTCHA and the{" "}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Privacy Policy
              </a>{" "}
              and{" "}
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{" "}
              apply.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Contact;
