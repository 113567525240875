import "./sectionone.styles.scss";
import WhyUsTitle from "../whyus-title/whyustitle.component";
import WhyUsText from "../whyussub/whyustext.component";

const SectionOne = (props) => {
  return (
    <div className="section-grid-wrapper">
      <div className="section-fill-left" />
      <div className="section-fill-right" />
      <div className="section-first-title-wrapper">
        <WhyUsTitle />
      </div>
      <div className="section-first-text-wrapper">
        <WhyUsText />
      </div>
    </div>
  );
};

export default SectionOne;
